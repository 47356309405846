

jQuery.noConflict()(function($){

    $('.slider-inicial').slick({

        dots: true,
        infinite: true,
        speed: 500,
        fade: true,
        cssEase: 'linear',
        arrows: true,
        autoplay: true,
        autoplaySpeed: 3000,
        adaptiveHeight: true,
        pauseOnHover:false,
        responsive: [
            {
              breakpoint: 620,
              settings: {
                  arrows: false,
              }
            },
            {
              breakpoint: 345,
              settings: {
                  arrows: false,
              }
            }
            ]

        // rtl: true,
        // variableWidth: true,
        
        });


      

        $('.slider-terceario').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2500,
       
            // rtl: true,
            // variableWidth: true,
            
            });


        $('.slider-secundario').each(function() {
            var slider = $(this);
            slider.slick({
                dots: true,
                infinite: true,
                speed: 500,
                autoplay: true,
                autoplaySpeed: 2000,
                fade: true,
                cssEase: 'linear',
                arrows: true,
                // variableWidth: true,
                // autoplay: true,
                // adaptiveHeight: true,
                responsive: [
                    {
                      breakpoint: 620,
                      settings: {
                          arrows: false,
                      }
                    },
                    {
                      breakpoint: 345,
                      settings: {
                          arrows: false,
                      }
                    }
                    ]
                
      
        });
          
  
          });


        var gridContainer = $('#cubeportfolio');
    
        gridContainer.imagesLoaded(function(){
            gridContainer.cubeportfolio({
                defaultFilter: '*',
                filters: '.vlt-portfolio-grid-filters',
                animationType: 'fadeOut',
                layoutMode: 'grid', //mosaic
                sortToPreventGaps: true,
                gapHorizontal: 30,
                gapVertical: 30,
                gridAdjustment: 'responsive',
                mediaQueries:
                [{
                    width: 1170,
                    cols: 3,
                }, {
                    width: 991,
                    cols: 3,
                }, {
                    width: 767,
                    cols: 2,
                }, {
                    width: 575,
                    cols: 1,
                }],
                displayType: 'default',
                displayTypeSpeed: 150,
            });
        });


        // var gridContainer2 = $('#cubeportfolio3');
        // gridContainer2.imagesLoaded(function(){
        //     gridContainer2.cubeportfolio({
        //         layoutMode: 'grid',
        //         gapHorizontal: 30,
        //         gapVertical: 30,
        //         mediaQueries:
        // [{
        //     width: 1170,
        //     cols: 3,
        // }, {
        //     width: 991,
        //     cols: 3,
        // }, {
        //     width: 767,
        //     cols: 2,
        // }, {
        //     width: 575,
        //     cols: 1,
        // }]          });
        //     });
});




		
 



jQuery.noConflict()(function($){
    var gridContainer = $('#cubeportfolio4');
    gridContainer.imagesLoaded(function(){
        gridContainer.cubeportfolio({
            defaultFilter: '*',
            filters: '.vlt-portfolio-grid-filters',
            animationType: 'fadeOut',
            layoutMode: 'grid', //mosaic
            sortToPreventGaps: true,
            gapHorizontal: 0,
            gapVertical: 20,
            gridAdjustment: 'responsive',
            mediaQueries:
            [{
                width: 1170,
                cols: 3,
            }, {
                width: 991,
                cols: 3,
            }, {
                width: 767,
                cols: 2,
            }, {
                width: 575,
                cols: 1,
            }],
            displayType: 'default',
            displayTypeSpeed: 150,
        });
    });
});

jQuery.noConflict()(function($){

    var gridContainer = $('#cubeportfolio8');

    gridContainer.imagesLoaded(function(){
        gridContainer.cubeportfolio({
            defaultFilter: '*',
            filters: '.vlt-portfolio-grid-filters',
            animationType: 'fadeOut',
            layoutMode: 'grid', //mosaic
                sortToPreventGaps: true,
            gridAdjustment: 'responsive',
            mediaQueries:
            [{
                width: 1170,
                cols: 3,
            }, {
                width: 991,
                cols: 3,
            }, {
                width: 767,
                cols: 2,
            }, {
                width: 575,
                cols: 1,
            }],
            displayType: 'default',
            displayTypeSpeed: 150,
        });
    });

    
});


jQuery.noConflict()(function($){

    var gridContainer = $('#cubeportfolio9');

    gridContainer.imagesLoaded(function(){
        gridContainer.cubeportfolio({
            defaultFilter: '*',
            filters: '.vlt-portfolio-grid-filters',
            animationType: 'fadeOut',
            layoutMode: 'grid', //mosaic
                sortToPreventGaps: true,
            gridAdjustment: 'responsive',
            mediaQueries:
            [{
                width: 1170,
                cols: 3,
            }, {
                width: 991,
                cols: 3,
            }, {
                width: 767,
                cols: 2,
            }, {
                width: 575,
                cols: 1,
            }],
            displayType: 'default',
            displayTypeSpeed: 150,
        });
    });

    
});


jQuery.noConflict()(function($){
  
    var gridContainer = $('#cubeportfolio3');
    gridContainer.imagesLoaded(function(){
        gridContainer.cubeportfolio({
            layoutMode: 'grid',
            gapHorizontal: 40,
            gapVertical: 40,
            mediaQueries:
    [{
        width: 1170,
        cols: 4,
    }, {
        width: 991,
        cols: 3,
    }, {
        width: 767,
        cols: 2,
    }, {
        width: 575,
        cols: 1,
    }]          });
        });
});

